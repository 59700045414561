import VueRouter from "vue-router";
import configUtilities from "@/vue/infrastructure/utils/configUtilities";

function DcRouter(modeType: any): VueRouter {
  return new VueRouter({
    mode: modeType,
    base: configUtilities.getBasePath(),
    routes: [
      {
        name: "magazine",
        path: "/:signboardAlias/:magazineAlias",
        component: () => import(/* webpackChunkName: "dm-page-home" */ "@/vue/components/dm-page-home/dm-page-home.vue"),
      },
      {
        name: "error",
        path: "/errors/:errorCode",
        component: () => import(/* webpackChunkName: "dm-page-error" */ "@/vue/components/dm-page-error/dm-page-error.vue"),
      },
      {
        path: "*",
        redirect: { name: "error", params: { errorCode: "404" } },
      },
    ],
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        return {
          selector: to.hash,
          offset: { x: 0, y: to.params.offsetY ? Number.parseInt(to.params.offsetY) : 0 },
        };
      }
      return { x: 0, y: 0 };
    },
  });
}
export default DcRouter;

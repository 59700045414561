import {RouterMode} from "vue-router";

class configUtilities {
  static readonly BASE_API_PATH: string = "api";
  static readonly BEARER_ACCESS_TOKEN_KEY: string = "dm_digital_magazine_bearer_access_token";
  static readonly BEARER_REFRESH_TOKEN_KEY: string = "dm_digital_magazine_bearer_refresh_token";
  static readonly BRAND_CONTENT_MAGAZINE: Array<string> = ["MGZ"];
  static readonly BRAND_CONTENT_SIGNBOARD: Array<string> = ["BRD"];

  public static getServerUrl(): string {
    const serverUrl: HTMLMetaElement = <HTMLMetaElement>document.getElementsByName("_server_url")[0];
    return serverUrl ? `${serverUrl.content}` : `${document.location.origin}`;
  }

  public static getBasePath(absolute?: boolean): string {
    const serverUrl: string = absolute ? `${this.getServerUrl()}` : "";
    const basePath: HTMLMetaElement = <HTMLMetaElement>document.getElementsByName("_base_path")[0];
    return basePath && basePath.content ? `${serverUrl}/${basePath.content}` : serverUrl;
  }

  public static getResourcesPath(): string {
    const resourcesPath: HTMLMetaElement = <HTMLMetaElement>document.getElementsByName("_resources_path")[0] || null;
    return resourcesPath ? `${this.getBasePath(true)}/${resourcesPath.content}` : ".";
  }

  public static getAppContextPath(absolute?: boolean): string {
    const serverUrl: string = absolute ? `${this.getServerUrl()}` : "";
    const appContentPath: HTMLMetaElement = <HTMLMetaElement>document.getElementsByName("_app_context_path")[0];
    return appContentPath && appContentPath.content ? `${serverUrl}/${appContentPath.content}` : serverUrl;
  }

  public static getApiContextPath(): string {
    const apiContextPath: HTMLMetaElement = <HTMLMetaElement>document.getElementsByName("_api_context_path")[0];
    return apiContextPath ? apiContextPath.content : "";
  }

  public static getAuthorizationData(): string {
    const authorizationData: HTMLMetaElement = <HTMLMetaElement>document.getElementsByName("_authorization_data")[0];
    return authorizationData.content || "";
  }

  public static getRoutingMode(): RouterMode {
    const routingMode: HTMLMetaElement = <HTMLMetaElement>document.getElementsByName("_routing_mode")[0] || null;
    return routingMode ? <RouterMode>routingMode.content : "history";
  }
}
export default configUtilities;

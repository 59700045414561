import { injectable, inject } from "inversify";
import { CONTAINER_TYPES } from "./../infrastructure/ioc/container-types";
import ServiceInterface from "./service-interface";
import { ApiInterface } from "../infrastructure/api/interfaces/api-interface";
import DmContent from "@/vue/domain/content/dm-content";
import DmContentType from "@/vue/domain/content/dm-content-type";
import DmDayOfWeek from "@/vue/domain/content/dm-days-of-week";
import DmProperty from "@/vue/domain/content/dm-property";
import DmSchedulingStatus from "@/vue/domain/content/dm-scheduling-status";
import configUtilities from "@/vue/infrastructure/utils/configUtilities";
import Utils from "@/vue/infrastructure/utils/helpers";
import DmBrand from "@/vue/domain/brand/dm-brand";

@injectable()
class Service implements ServiceInterface {
  @inject(CONTAINER_TYPES.ApiInterface)
  private api: ApiInterface;

  public async getBrand(): Promise<DmBrand> {
    return Promise.resolve(await this.api.getBrand()).then((brandData: any) => {
      return this.createBrand(brandData);
    });
  }

  public async getBrandContents(params: any): Promise<Array<DmContent>> {
    return Promise.resolve(await this.api.getBrandContents(params)).then((contents: any) => {
      contents.sort((contentA: any, contentB: any) => (contentA.priority <= contentB.priority ? 1 : -1));
      return contents.map((contentData: any) => {
        return this.createContent(contentData);
      });
    });
  }

  public async login(): Promise<any> {
    const formData = new FormData();
    formData.append("grant_type", "client_credentials");
    formData.append("scope", "read write");
    return Promise.resolve(await this.api.login(formData)).then((tokenData: any) => {
      Utils.localStorageSetItem(configUtilities.BEARER_ACCESS_TOKEN_KEY, tokenData.access_token);
      return tokenData;
    });
  }

  public createBrand(brandData: any): DmBrand {
    const properties: Array<any> = brandData.properties ? brandData.properties.map((property: string) => property) : [];
    return new DmBrand(brandData.identifier, brandData.alias, brandData.description, brandData.notes, properties);
  }

  public createContent(contentData: any): DmContent {
    return new DmContent(
      contentData.uniqueId,
      contentData.alias,
      contentData.promotion,
      new DmContentType(contentData.type.code, contentData.type.name),
      contentData.name,
      contentData.priority,
      contentData.startDate,
      contentData.endDate,
      contentData.startTime,
      contentData.endTime,
      contentData.daysOfWeek.map((dayOfWeek: string) => DmDayOfWeek.getEnum(dayOfWeek)),
      contentData.products,
      DmSchedulingStatus.getEnum(contentData.schedulingStatus),
      contentData.properties.map((property: any) => new DmProperty(property.type, property.code, property.label, property.values))
    );
  }
}
export default Service;

import { Enumify } from "enumify";

class DmSchedulingStatus extends Enumify {
  static VALID = new DmSchedulingStatus({
    get label(): string {
      return "enum-dm-scheduling-status.valid";
    },
  });
  static FORTHCOMING = new DmSchedulingStatus({
    get label(): string {
      return "enum-dm-scheduling-status.forthcoming";
    },
  });
  static EXPIRED = new DmSchedulingStatus({
    get label(): string {
      return "enum-dm-scheduling-status.expired";
    },
  });
  label!: string;
  constructor(props: { [k: string]: any }) {
    super();
    Object.defineProperties(this, Object.getOwnPropertyDescriptors(props));
  }

  static getEnum(enumKey: string): DmSchedulingStatus {
    switch (enumKey) {
      case DmSchedulingStatus.VALID.enumKey:
        return DmSchedulingStatus.VALID;
      case DmSchedulingStatus.FORTHCOMING.enumKey:
        return DmSchedulingStatus.FORTHCOMING;
      case DmSchedulingStatus.EXPIRED.enumKey:
        return DmSchedulingStatus.EXPIRED;
      default:
        return null;
    }
  }
}
export default DmSchedulingStatus;

import { injectable } from "inversify";
import { ApiInterface } from "./interfaces/api-interface";
import Http from "axios";
import { ApiUrlRequestBuilder } from "@/vue/infrastructure/api/api-url-request-builder";
import Utils from "@/vue/infrastructure/utils/helpers";
import * as qs from "qs";
import configUtilities from "@/vue/infrastructure/utils/configUtilities";

@injectable()
class Api implements ApiInterface {
  private apiUrlBuilder: ApiUrlRequestBuilder = null;

  constructor() {
    this.apiUrlBuilder = new ApiUrlRequestBuilder();
    this.setDefaultHeaders();
  }

  public setDefaultHeaders() {
    const barearAccessToken: string = Utils.localStorageGetItem(configUtilities.BEARER_REFRESH_TOKEN_KEY);
    Http.defaults.headers.common = {};

    if (barearAccessToken != null) {
      Http.defaults.headers.common = {
        Authorization: `Bearer ${barearAccessToken}`,
      };
    }
  }

  public getBrand(): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(""))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getBrandContents(params: any): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`contents`), {
        params: {
          typeCode: params.typesCodes || null,
          alias: params.aliases || undefined,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
          });
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public login(formData: FormData): any {
    return new Promise((resolve, reject) => {
      Http.post(this.apiUrlBuilder.getOuthTokenPath(), formData, this.apiUrlBuilder.getHeaderForBasicAuthorization())
        .then((response) => {
          Http.defaults.headers.common = {
            Authorization: `Bearer ${response.data.access_token}`,
          };
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export { Api };

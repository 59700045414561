import { MutationTree } from "vuex";
import StoreStateInterface from "./store-state-interface";

const storeMutations: MutationTree<StoreStateInterface> = {
  setBrandMutation: (state, brand) => {
    state.brand = brand;
  },

  setSignboardContentsMutation: (state, brandContents) => {
    state.signboardContents = brandContents;
  },

  setMagazineContentsMutation: (state, brandContents) => {
    state.magazineContents = brandContents;
  },
};

export default storeMutations;

export default class DmContentType {
  private _code: string;
  private _name: string;

  constructor(code: string, name: string) {
    this._code = code;
    this._name = name;
  }

  get code(): string {
    return this._code;
  }

  set code(code: string) {
    this._code = code;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }
}

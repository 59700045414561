import { Enumify } from "enumify";

class DmDayOfWeek extends Enumify {
  static MONDAY = new DmDayOfWeek({
    get label(): string {
      return "enum-dm-day-of-week.monday";
    },
  });
  static TUESDAY = new DmDayOfWeek({
    get label(): string {
      return "enum-dm-day-of-week.tuesday";
    },
  });
  static WEDNESDAY = new DmDayOfWeek({
    get label(): string {
      return "enum-dm-day-of-week.wednesday";
    },
  });
  static THURSDAY = new DmDayOfWeek({
    get label(): string {
      return "enum-dm-day-of-week.thursday";
    },
  });
  static FRIDAY = new DmDayOfWeek({
    get label(): string {
      return "enum-dm-day-of-week.friday";
    },
  });
  static SATURDAY = new DmDayOfWeek({
    get label(): string {
      return "enum-dm-day-of-week.saturday";
    },
  });
  static SUNDAY = new DmDayOfWeek({
    get label(): string {
      return "enum-dm-day-of-week.sunday";
    },
  });
  static _ = DmDayOfWeek.closeEnum();

  label!: string;
  constructor(props: { [k: string]: any }) {
    super();
    Object.defineProperties(this, Object.getOwnPropertyDescriptors(props));
  }

  static getEnum(enumKey: string): DmDayOfWeek {
    switch (enumKey) {
      case DmDayOfWeek.MONDAY.enumKey:
        return DmDayOfWeek.MONDAY;
      case DmDayOfWeek.TUESDAY.enumKey:
        return DmDayOfWeek.TUESDAY;
      case DmDayOfWeek.WEDNESDAY.enumKey:
        return DmDayOfWeek.WEDNESDAY;
      case DmDayOfWeek.THURSDAY.enumKey:
        return DmDayOfWeek.THURSDAY;
      case DmDayOfWeek.FRIDAY.enumKey:
        return DmDayOfWeek.FRIDAY;
      case DmDayOfWeek.SATURDAY.enumKey:
        return DmDayOfWeek.SATURDAY;
      case DmDayOfWeek.SUNDAY.enumKey:
        return DmDayOfWeek.SUNDAY;
      default:
        return null;
    }
  }
}
export default DmDayOfWeek;

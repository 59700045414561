import DmProperty from "@/vue/domain/content/dm-property";

export default class DmBrand {
  private _identifier: string;
  private _alias: string;
  private _description: string;
  private _notes: string;
  private _properties: Array<DmProperty>;

  constructor(identifier: string, alias: string, description: string, notes: string, properties: Array<DmProperty>) {
    this._identifier = identifier;
    this._alias = alias;
    this._description = description;
    this._notes = notes;
    this._properties = properties;
  }

  get identifier(): string {
    return this._identifier;
  }

  set identifier(identifier: string) {
    this._identifier = identifier;
  }

  get alias(): string {
    return this._alias;
  }

  set alias(alias: string) {
    this._alias = alias;
  }

  get description(): string {
    return this._description;
  }

  set description(description: string) {
    this._description = description;
  }

  get notes(): string {
    return this._notes;
  }

  set notes(notes: string) {
    this._notes = notes;
  }

  get properties(): Array<DmProperty> {
    return this._properties;
  }

  set properties(properties: Array<DmProperty>) {
    this._properties = properties;
  }
}

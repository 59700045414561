import { ActionTree } from "vuex";
import StoreStateInterface from "./store-state-interface";
import DmContent from "@/vue/domain/content/dm-content";
import DmBrand from "@/vue/domain/brand/dm-brand";

const storeActions: ActionTree<StoreStateInterface, any> = {
  setBrand: ({ commit }, brand: DmBrand) => {
    return commit("setBrandMutation", brand);
  },

  setSignboardContents: ({ commit, state }, signboardContents: Array<DmContent>) => {
    return commit("setSignboardContentsMutation", signboardContents);
  },

  setMagazineContents: ({ commit, state }, magazineContents: Array<DmContent>) => {
    return commit("setMagazineContentsMutation", magazineContents);
  },
};

export default storeActions;

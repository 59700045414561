import UrlBuilderInterface from "./interfaces/url-builder-interface";
import { injectable } from "inversify";
import configUtilities from "@/vue/infrastructure/utils/configUtilities";

@injectable()
class ApiUrlRequestBuilder implements UrlBuilderInterface {
  public getEndPoint(endpoint: string): string {
    endpoint = endpoint ? `/${endpoint}` : "";
    return `${configUtilities.getAppContextPath(true)}/${configUtilities.BASE_API_PATH}/${configUtilities.getApiContextPath()}${endpoint}`;
  }

  public getOuthTokenPath(): string {
    return `${configUtilities.getAppContextPath(true)}/oauth/token`;
  }

  public getHeaderForBasicAuthorization(): any {
    return {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${window.btoa(configUtilities.getAuthorizationData())}`,
      },
    };
  }

  public getJsonHeadersOption(): any {
    return { headers: { "Content-Type": "application/json" } };
  }
}

export { ApiUrlRequestBuilder };

import "intersection-observer";
import Vue from "vue";
import VueI18n from "vue-i18n";
import VueRouter from "vue-router";
import { CONTAINER_TYPES } from "../ioc/container-types";
import dictionary from "../internationalization/messages";
import Router from "../router/router";
import Store from "./../store/store";
import { ApplicationInterface } from "./../setup/application-interface";
import { injectable, inject } from "inversify";
import ServiceInterface from "./../../service/service-interface";
import "@fortawesome/fontawesome-free/css/all.css";
import Utils from "@/vue/infrastructure/utils/helpers";
import configUtilities from "@/vue/infrastructure/utils/configUtilities";

@injectable()
class Application implements ApplicationInterface {
  @inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  static readonly APPLICATION_ROOT_NODE_SELECTOR: string = "#app-digital-magazine";
  static readonly APPLICATION_DEFAULT_LOCALE: string = "it";

  private i18n: any = null;
  private appRouter: VueRouter = null;

  public run() {
    const applicationRootNode: HTMLDivElement = <HTMLDivElement>Application.applicationRootNode();
    if (applicationRootNode != null) {
      this.init();
      this.internationalizationSetup();
      this.routerSetup();
      this.startApplication({});
    } else {
      alert("Application root missing");
    }
  }

  private static applicationRootNode(): null | Element {
    return document.querySelector(Application.APPLICATION_ROOT_NODE_SELECTOR);
  }

  private init() {
    Vue.use(VueI18n);
    Vue.use(VueRouter);
  }

  private internationalizationSetup(): void {
    this.i18n = new VueI18n({
      locale: Application.APPLICATION_DEFAULT_LOCALE,
      messages: dictionary,
    });
  }

  private routerSetup(): void {
    const routingMode: any = configUtilities.getRoutingMode();
    this.appRouter = Router(routingMode);
    this.appRouter.beforeEach((to: any, from: any, next: any) => {
      const documentTitle: string = to.meta.title ? this.i18n.t(to.meta.title).toString() : null;
      document.title = documentTitle ? `${documentTitle} | ${Utils.APP_NAME}` : "";
      /*
      if (to.name && !to.name.includes('-no-store')) {
        const history: Array<any> = Store.getters.history.length === Store.getters.historyIndex ? [...Store.getters.history, to] : Store.getters.history.map((story: any, index: number) => (Store.getters.historyIndex === index ? to : story));
        Store.dispatch("setHistory", history);
        Store.dispatch("setHistoryIndex", Store.getters.historyIndex + 1);
      }

 */
      next();
    });
  }

  private startApplication(configParams: any) {
    const ApplicationComponent = () => import(/* webpackChunkName: 'application' */ "./../../components/application.vue");
    new Vue({
      el: Application.APPLICATION_ROOT_NODE_SELECTOR,
      store: Store,
      router: this.appRouter,
      i18n: this.i18n,
      render: (h) =>
        h(ApplicationComponent, {
          props: {
            accountEnabled: configParams.accountEnabled,
            passwordEnabled: configParams.passwordEnabled,
          },
        }),
      provide: {
        [CONTAINER_TYPES.ServiceInterface]: this.service,
      },
    });
  }
}

export { Application };

import * as Qs from "qs";
import DmProperty from "@/vue/domain/content/dm-property";
import configUtilities from "@/vue/infrastructure/utils/configUtilities";

class Utils {
  static readonly APP_NAME: string = "SMT Digitalflyer";

  static readonly ISO_8601_STRING_PATTERN: string = "YYYYMMDDHHmmss";
  static readonly DATE_STRING_PATTER: string = "DD/MM/YYYY";
  static readonly DATE_STRING_SHORT_PATTER: string = "DD/MM";

  static readonly PROPERTY_BACKGROUND_MOBILE: string = "BACKGROUND_MOBILE";

  static readonly PROPERTY_BACKGROUND_IMAGE: string = "BACKGROUND_IMAGE";
  static readonly PROPERTY_BACKGROUND_IMAGE_PAGE_HEADER: string = "BACKGROUND_IMAGE_PAGE_HEADER";
  static readonly PROPERTY_BACKGROUND_COLOR: string = "BACKGROUND_COLOR";
  static readonly PROPERTY_POSITION: string = "POSITION";

  static readonly PROPERTY_LOGO: string = "LOGO";
  static readonly PROPERTY_LOGO_FOOTER: string = "LOGO_FOOTER";
  static readonly PROPERTY_LOGO_HEADER: string = "LOGO_HEADER";
  static readonly PROPERTY_HEADER_MOBILE: string = "HEADER_MOBILE";
  static readonly PROPERTY_BACKGROUND_POSITION: string = "BACKGROUND_POSITION";
  static readonly PROPERTY_CAROUSEL_AUTOPLAY: string = "CAROUSEL_AUTOPLAY";
  static readonly PROPERTY_PREVIEW: string = "PREVIEW";
  static readonly PROPERTY_COVER: string = "COVER";
  static readonly PROPERTY_GALLERY: string = "IMAGES";
  static readonly PROPERTY_DATE: string = "DATE";
  static readonly PROPERTY_TITLE: string = "TITLE";
  static readonly PROPERTY_TEXT: string = "TEXT";
  static readonly PROPERTY_BODY: string = "BODY";
  static readonly PROPERTY_HEADER: string = "HEADER";
  static readonly PROPERTY_PDF: string = "PDF";
  static readonly PROPERTY_URL: string = "URL";
  static readonly PROPERTY_DIFFICULTY_LEVEL: string = "DIFFICULTY_LEVEL";
  static readonly PROPERTY_PREPARATION_TIME: string = "PREPARATION-TIME";
  static readonly PROPERTY_COOKING_TIME: string = "COOKING-TIME";
  static readonly PROPERTY_SERVINGS: string = "SERVINGS";
  static readonly PROPERTY_INGREDIENTS: string = "INGREDIENTS";
  static readonly PROPERTY_ISSUU_PUBLISHED_URL: string = "ISSUU-PUBLISHED-URL";
  static readonly PROPERTY_FOOTER: string = "FOOTER";
  static readonly PROPERTY_LINK: string = "INTERNAL_LINK";
  static readonly PROPERTY_CONTENT_HTML: string = "HTML_CONTENT";
  static readonly PROPERTY_CONTENT_VIDEO: string = "VIDEO_CONTENT";
  static readonly PROPERTY_CONTENT_IMAGE: string = "IMAGE_CONTENT";
  static readonly PROPERTY_CONTENT_PRIMARY: string = "PRIMARY";
  static readonly PROPERTY_PDF_LINK_TARGET: string = "PDF_LINK_TARGET";

  static readonly PROPERTY_SENDY_TITLE: string = "SENDY_TITLE";
  static readonly PROPERTY_SENDY_TEXT: string = "SENDY_TEXT";
  static readonly PROPERTY_SENDY_PRIVACY_TEXT: string = "SENDY_PRIVACY_TEXT";
  static readonly PROPERTY_SENDY_LIST_ID: string = "SENDY_LIST_ID";
  static readonly PROPERTY_SENDY_LASTNAME_FIELD: string = "SENDY_LASTNAME_FIELD";
  static readonly PROPERTY_SENDY_STORE_FIELD: string = "SENDY_STORE_FIELD";

  static readonly PROPERTY_STORE_CUSTOMER_CARE_URL: string = "CUSTOMER_CARE_URL";
  static readonly PROPERTY_STORE_CUSTOMER_CARE_LABEL: string = "CUSTOMER_CARE_LABEL";
  static readonly PROPERTY_PRODUCT_PREVIEW: string = "IMAGES";
  static readonly PROPERTY_PRODUCT_FINAL_PRICE_FOR_UNIT: string = "END-KG-LT-PRICE";
  static readonly PROPERTY_PRODUCT_UNIT: string = "MEASURE-UNIT";
  static readonly PROPERTY_PRODUCT_EAN: string = "EAN";
  static readonly PROPERTY_PRODUCT_DISCOUNT: string = "DISCOUNT";
  static readonly PROPERTY_PRODUCT_DISCOUNT_PERCENTUAL: string = "DISCOUNT-RATE";
  static readonly PROPERTY_PRODUCT_INITIAL_PRICE: string = "INITIAL-PRICE";
  static readonly PROPERTY_PRODUCT_FINAL_PRICE: string = "END-PRICE";
  static readonly PROPERTY_PRODUCT_DATE_FROM: string = "START-DATE-VALIDITY";
  static readonly PROPERTY_PRODUCT_DATE_TO: string = "END-DATE-VALIDITY";
  static readonly PROPERTY_PRODUCT_INGREDIENTS: string = "INGREDIENTS";
  static readonly PROPERTY_PRODUCT_NUTRITIONAL_VALUES: string = "NUTRITIONAL-VALUES";
  static readonly PROPERTY_PRODUCT_NUTRITIONAL_VALUES_CALCULATED: string = "CALCULATED-NUTRITION";
  static readonly PROPERTY_PRODUCT_POINTS: string = "POINTS";
  static readonly PROPERTY_PRODUCT_CONTRIBUTION_POINTS: string = "CONTRIBUTION-POINTS";
  static readonly PROPERTY_PRODUCT_CONTRIBUTION_PRICE: string = "CONTRIBUTION-PRICE";
  static readonly PROPERTY_PRODUCT_BRAND_LOGOS: string = "BRAND-LOGOS";
  static readonly PROPERTY_PRODUCT_PROPERTY_LOGOS: string = "PROPERTY-LOGOS";
  static readonly PROPERTY_PRODUCT_URL_ECOMMERCE: string = "URL-ECOMMERCE";
  static readonly PROPERTY_PRODUCT_URL_VIDEO: string = "URL-VIDEO";
  static readonly PROPERTY_PRODUCT_URL: string = "URL";

  static readonly PROPERTY_BUTTON_LABEL: string = "LABEL";
  static readonly PROPERTY_BUTTON_ICON_CLASS: string = "ICON_CLASS";
  static readonly PROPERTY_BUTTON_ICON_IMAGE: string = "ICON_IMAGE";
  static readonly PROPERTY_BUTTON_BACKGROUND_COLOR: string = "BACKGROUND_COLOR";  
  static readonly PROPERTY_BUTTON_COLOR: string = "COLOR";
  static readonly PROPERTY_BUTTON_VISIBILITY: string = "VISIBILITY";

  static readonly PROPERTY_CARD_CLUB_MY_AREA_LABEL: string = "MY_AREA_LABEL";
  static readonly PROPERTY_CARD_CLUB_MY_AREA_URL: string = "MY_AREA_URL";

  static readonly PROPERTY_DETAILS_BUTTON: string = "DETAILS-BUTTON";
  static readonly GOOGLE_MAP_URL: string = "https://www.google.com/maps/dir//";

  static readonly LOCAL_STORAGE_CLIENT_NOTIFICATION: string = "client-notification-actived";
  static readonly LOCAL_STORAGE_GEO_LOCALIZATION: string = "geolocation-actived";

  static readonly PROPERTY_BANNER_POSITION_PRODUCTS_LIST: string = "PRODUCTS_LIST";

  static readonly customComponentsConfig: any = {};

  private static readonly XMLHttpRequestSetup = {
    paramsSerializer: function (params: any): any {
      return Qs.stringify(params, {
        arrayFormat: "repeat",
      });
    },
  };

  public static buildRequestPayload(data: any): any {
    return Object.assign(data, Utils.XMLHttpRequestSetup);
  }

  public static getLanguageCode(): string {
    return document.documentElement !== null ? document.documentElement.lang : "";
  }

  public static isArrayEquals(arrayOne: Array<any>, arrayTwo: Array<any>): boolean {
    if (!arrayOne || !arrayTwo) {
      return false;
    }

    if (arrayOne.length !== arrayTwo.length) {
      return false;
    }

    for (let i = 0, l = arrayOne.length; i < l; i++) {
      if (arrayOne[i] instanceof Array && arrayTwo[i] instanceof Array) {
        if (!this.isArrayEquals(arrayOne[i], arrayTwo[i])) {
          return false;
        }
      } else if (arrayOne[i] !== arrayTwo[i]) {
        return false;
      }
    }
    return true;
  }

  public static downloadFile(downloadData: ArrayBuffer, filename: string, contentType: string): void {
    const data = new Blob([downloadData], { type: contentType });
    const link = document.createElement("a");
    document.body.appendChild(link);
    link.href = window.URL.createObjectURL(data);
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(link.href);
    link.remove();
  }

  public static setWorking(isWorking: boolean, element?: HTMLElement): void {
    element = element || <HTMLElement>document.documentElement.querySelector("body");

    if (element !== null) {
      isWorking ? element.classList.add("ui-working") : element.classList.remove("ui-working");
    }
  }

  public static unstringify(value: any, sep: any, eq: any): Array<any> {
    if (typeof value === "string") {
      const properties = decodeURIComponent(value).split(sep);
      const arr = [];
      for (let i = 0; i < properties.length; i++) {
        const prop = properties[i].split(eq);
        const obj: any = {};
        obj[prop[0]] = prop[1];
        arr.push(obj);
      }
      return arr;
    } else {
      return value;
    }
  }

  public static getLocaleDateString(hours: boolean): string {
    const formats: any = {
      "ar-SA": "dd/MM/yy",
      "bg-BG": "dd.M.yyyy",
      "ca-ES": "dd/MM/yyyy",
      "zh-TW": "yyyy/M/d",
      "cs-CZ": "d.M.yyyy",
      "da-DK": "dd-MM-yyyy",
      "de-DE": "dd.MM.yyyy",
      "el-GR": "d/M/yyyy",
      "en-US": "M/d/yyyy",
      "fi-FI": "d.M.yyyy",
      "fr-FR": "dd/MM/yyyy",
      "he-IL": "dd/MM/yyyy",
      "hu-HU": "yyyy. MM. dd.",
      "is-IS": "d.M.yyyy",
      "it-IT": "dd/MM/yyyy",
      "ja-JP": "yyyy/MM/dd",
      "ko-KR": "yyyy-MM-dd",
      "nl-NL": "d-M-yyyy",
      "nb-NO": "dd.MM.yyyy",
      "pl-PL": "yyyy-MM-dd",
      "pt-BR": "d/M/yyyy",
      "ro-RO": "dd.MM.yyyy",
      "ru-RU": "dd.MM.yyyy",
      "hr-HR": "d.M.yyyy",
      "sk-SK": "d. M. yyyy",
      "sq-AL": "yyyy-MM-dd",
      "sv-SE": "yyyy-MM-dd",
      "th-TH": "d/M/yyyy",
      "tr-TR": "dd.MM.yyyy",
      "ur-PK": "dd/MM/yyyy",
      "id-ID": "dd/MM/yyyy",
      "uk-UA": "dd.MM.yyyy",
      "be-BY": "dd.MM.yyyy",
      "sl-SI": "d.M.yyyy",
      "et-EE": "d.MM.yyyy",
      "lv-LV": "yyyy.MM.dd.",
      "lt-LT": "yyyy.MM.dd",
      "fa-IR": "MM/dd/yyyy",
      "vi-VN": "dd/MM/yyyy",
      "hy-AM": "dd.MM.yyyy",
      "az-Latn-AZ": "dd.MM.yyyy",
      "eu-ES": "yyyy/MM/dd",
      "mk-MK": "dd.MM.yyyy",
      "af-ZA": "yyyy/MM/dd",
      "ka-GE": "dd.MM.yyyy",
      "fo-FO": "dd-MM-yyyy",
      "hi-IN": "dd-MM-yyyy",
      "ms-MY": "dd/MM/yyyy",
      "kk-KZ": "dd.MM.yyyy",
      "ky-KG": "dd.MM.yy",
      "sw-KE": "M/d/yyyy",
      "uz-Latn-UZ": "dd/MM yyyy",
      "tt-RU": "dd.MM.yyyy",
      "pa-IN": "dd-MM-yy",
      "gu-IN": "dd-MM-yy",
      "ta-IN": "dd-MM-yyyy",
      "te-IN": "dd-MM-yy",
      "kn-IN": "dd-MM-yy",
      "mr-IN": "dd-MM-yyyy",
      "sa-IN": "dd-MM-yyyy",
      "mn-MN": "yy.MM.dd",
      "gl-ES": "dd/MM/yy",
      "kok-IN": "dd-MM-yyyy",
      "syr-SY": "dd/MM/yyyy",
      "dv-MV": "dd/MM/yy",
      "ar-IQ": "dd/MM/yyyy",
      "zh-CN": "yyyy/M/d",
      "de-CH": "dd.MM.yyyy",
      "en-GB": "dd/MM/yyyy",
      "es-MX": "dd/MM/yyyy",
      "fr-BE": "d/MM/yyyy",
      "it-CH": "dd.MM.yyyy",
      "nl-BE": "d/MM/yyyy",
      "nn-NO": "dd.MM.yyyy",
      "pt-PT": "dd-MM-yyyy",
      "sr-Latn-CS": "d.M.yyyy",
      "sv-FI": "d.M.yyyy",
      "az-Cyrl-AZ": "dd.MM.yyyy",
      "ms-BN": "dd/MM/yyyy",
      "uz-Cyrl-UZ": "dd.MM.yyyy",
      "ar-EG": "dd/MM/yyyy",
      "zh-HK": "d/M/yyyy",
      "de-AT": "dd.MM.yyyy",
      "en-AU": "d/MM/yyyy",
      "es-ES": "dd/MM/yyyy",
      "fr-CA": "yyyy-MM-dd",
      "sr-Cyrl-CS": "d.M.yyyy",
      "ar-LY": "dd/MM/yyyy",
      "zh-SG": "d/M/yyyy",
      "de-LU": "dd.MM.yyyy",
      "en-CA": "dd/MM/yyyy",
      "es-GT": "dd/MM/yyyy",
      "fr-CH": "dd.MM.yyyy",
      "ar-DZ": "dd-MM-yyyy",
      "zh-MO": "d/M/yyyy",
      "de-LI": "dd.MM.yyyy",
      "en-NZ": "d/MM/yyyy",
      "es-CR": "dd/MM/yyyy",
      "fr-LU": "dd/MM/yyyy",
      "ar-MA": "dd-MM-yyyy",
      "en-IE": "dd/MM/yyyy",
      "es-PA": "MM/dd/yyyy",
      "fr-MC": "dd/MM/yyyy",
      "ar-TN": "dd-MM-yyyy",
      "en-ZA": "yyyy/MM/dd",
      "es-DO": "dd/MM/yyyy",
      "ar-OM": "dd/MM/yyyy",
      "en-JM": "dd/MM/yyyy",
      "es-VE": "dd/MM/yyyy",
      "ar-YE": "dd/MM/yyyy",
      "en-029": "MM/dd/yyyy",
      "es-CO": "dd/MM/yyyy",
      "ar-SY": "dd/MM/yyyy",
      "en-BZ": "dd/MM/yyyy",
      "es-PE": "dd/MM/yyyy",
      "ar-JO": "dd/MM/yyyy",
      "en-TT": "dd/MM/yyyy",
      "es-AR": "dd/MM/yyyy",
      "ar-LB": "dd/MM/yyyy",
      "en-ZW": "M/d/yyyy",
      "es-EC": "dd/MM/yyyy",
      "ar-KW": "dd/MM/yyyy",
      "en-PH": "M/d/yyyy",
      "es-CL": "dd-MM-yyyy",
      "ar-AE": "dd/MM/yyyy",
      "es-UY": "dd/MM/yyyy",
      "ar-BH": "dd/MM/yyyy",
      "es-PY": "dd/MM/yyyy",
      "ar-QA": "dd/MM/yyyy",
      "es-BO": "dd/MM/yyyy",
      "es-SV": "dd/MM/yyyy",
      "es-HN": "dd/MM/yyyy",
      "es-NI": "dd/MM/yyyy",
      "es-PR": "dd/MM/yyyy",
      "am-ET": "d/M/yyyy",
      "tzm-Latn-DZ": "dd-MM-yyyy",
      "iu-Latn-CA": "d/MM/yyyy",
      "sma-NO": "dd.MM.yyyy",
      "mn-Mong-CN": "yyyy/M/d",
      "gd-GB": "dd/MM/yyyy",
      "en-MY": "d/M/yyyy",
      "prs-AF": "dd/MM/yy",
      "bn-BD": "dd-MM-yy",
      "wo-SN": "dd/MM/yyyy",
      "rw-RW": "M/d/yyyy",
      "qut-GT": "dd/MM/yyyy",
      "sah-RU": "MM.dd.yyyy",
      "gsw-FR": "dd/MM/yyyy",
      "co-FR": "dd/MM/yyyy",
      "oc-FR": "dd/MM/yyyy",
      "mi-NZ": "dd/MM/yyyy",
      "ga-IE": "dd/MM/yyyy",
      "se-SE": "yyyy-MM-dd",
      "br-FR": "dd/MM/yyyy",
      "smn-FI": "d.M.yyyy",
      "moh-CA": "M/d/yyyy",
      "arn-CL": "dd-MM-yyyy",
      "ii-CN": "yyyy/M/d",
      "dsb-DE": "d. M. yyyy",
      "ig-NG": "d/M/yyyy",
      "kl-GL": "dd-MM-yyyy",
      "lb-LU": "dd/MM/yyyy",
      "ba-RU": "dd.MM.yy",
      "nso-ZA": "yyyy/MM/dd",
      "quz-BO": "dd/MM/yyyy",
      "yo-NG": "d/M/yyyy",
      "ha-Latn-NG": "d/M/yyyy",
      "fil-PH": "M/d/yyyy",
      "ps-AF": "dd/MM/yy",
      "fy-NL": "d-M-yyyy",
      "ne-NP": "M/d/yyyy",
      "se-NO": "dd.MM.yyyy",
      "iu-Cans-CA": "d/M/yyyy",
      "sr-Latn-RS": "d.M.yyyy",
      "si-LK": "yyyy-MM-dd",
      "sr-Cyrl-RS": "d.M.yyyy",
      "lo-LA": "dd/MM/yyyy",
      "km-KH": "yyyy-MM-dd",
      "cy-GB": "dd/MM/yyyy",
      "bo-CN": "yyyy/M/d",
      "sms-FI": "d.M.yyyy",
      "as-IN": "dd-MM-yyyy",
      "ml-IN": "dd-MM-yy",
      "en-IN": "dd-MM-yyyy",
      "or-IN": "dd-MM-yy",
      "bn-IN": "dd-MM-yy",
      "tk-TM": "dd.MM.yy",
      "bs-Latn-BA": "d.M.yyyy",
      "mt-MT": "dd/MM/yyyy",
      "sr-Cyrl-ME": "d.M.yyyy",
      "se-FI": "d.M.yyyy",
      "zu-ZA": "yyyy/MM/dd",
      "xh-ZA": "yyyy/MM/dd",
      "tn-ZA": "yyyy/MM/dd",
      "hsb-DE": "d. M. yyyy",
      "bs-Cyrl-BA": "d.M.yyyy",
      "tg-Cyrl-TJ": "dd.MM.yy",
      "sr-Latn-BA": "d.M.yyyy",
      "smj-NO": "dd.MM.yyyy",
      "rm-CH": "dd/MM/yyyy",
      "smj-SE": "yyyy-MM-dd",
      "quz-EC": "dd/MM/yyyy",
      "quz-PE": "dd/MM/yyyy",
      "hr-BA": "d.M.yyyy.",
      "sr-Latn-ME": "d.M.yyyy",
      "sma-SE": "yyyy-MM-dd",
      "en-SG": "d/M/yyyy",
      "ug-CN": "yyyy-M-d",
      "sr-Cyrl-BA": "d.M.yyyy",
      "es-US": "M/d/yyyy",
    };

    const dateFormat: string = formats[navigator.language] || "dd/MM/yyyy";
    return hours ? `${dateFormat.toUpperCase()} HH:mm` : dateFormat.toUpperCase();
  }

  public static localStorageSetItem(key: string, value: string): void {
    const apiPath: string = configUtilities.getApiContextPath().replace("/", ".");
    const contextPath: string = configUtilities.getAppContextPath().replace("/", "");
    localStorage.setItem(`${apiPath}.${contextPath}.${key}`, value);
  }

  public static localStorageGetItem(key: string): string {
    const apiPath: string = configUtilities.getApiContextPath().replace("/", ".");
    const contextPath: string = configUtilities.getAppContextPath().replace("/", "");
    return localStorage.getItem(`${apiPath}.${contextPath}.${key}`);
  }

  public static localStorageRemoveItem(key: string): void {
    const apiPath: string = configUtilities.getApiContextPath().replace("/", ".");
    const contextPath: string = configUtilities.getAppContextPath().replace("/", "");
    localStorage.removeItem(`${apiPath}.${contextPath}.${key}`);
  }

  public static dateFromTimestamp(timestamp: string, time: boolean): Date {
    let output: Date = new Date();
    if (timestamp) {
      const year: number = parseInt(timestamp.substr(0, 4));
      const month: number = parseInt(timestamp.substr(4, 2)) - 1;
      const day: number = parseInt(timestamp.substr(6, 2));
      const hours: number = parseInt(timestamp.substr(8, 2));
      const minutes: number = parseInt(timestamp.substr(10, 2));
      const seconds: number = parseInt(timestamp.substr(12, 2));

      output = time ? new Date(year, month, day, hours, minutes, seconds) : new Date(year, month, day);
    }
    return output;
  }

  public static addLayer(layerId: string, node: any): void {
    const layer: HTMLElement = document.createElement("div");
    layer.id = layerId;
    layer.classList.add("modules-layer");
    layer.setAttribute("style", "opacity: 0; z-index: 1;");
    node.prepend(layer);
  }

  public static removeLayer(layerId: string): void {
    const layer: any = document.getElementById(layerId);
    layer.remove();
  }

  public static defaultApiErrorHandler(error: any, componentCaller?: any) {
    switch (error.response.status) {
      case 401:
        if (componentCaller) {
          componentCaller.$root.$emit("login");
        }
        break;
      default:
        componentCaller.$router.push({ name: "error", params: { errorCode: error.response.status } });
    }
  }

  public static getPropertyImageUrl(propertiesHolder: any, code: string, defaultUrl: string): string {
    const property: DmProperty = propertiesHolder && propertiesHolder.properties ? propertiesHolder.properties.find((property: DmProperty) => property.type === "FILE" && property.code === code) || null : null;
    return property && property.values.length > 0 ? `${configUtilities.getAppContextPath(true)}/files/${property.values[0].uniqueId}/${property.values[0].basename}.${property.values[0].extension}` : defaultUrl;
  }

  public static getPropertyImagesUrl(protertiesHolder: any, code: string): Array<string> {
    const property: DmProperty = protertiesHolder && protertiesHolder.properties ? protertiesHolder.properties.find((property: DmProperty) => property.type === "FILE" && property.code === code) || null : null;

    return property && property.values.length > 0
      ? property.values.map((value: any) => {
          return `${configUtilities.getAppContextPath(true)}/files/${value.uniqueId}/${value.basename}.${value.extension}`;
        })
      : [];
  }

  public static getPropertyValue(protertiesHolder: any, code: string, type: string, defaultValue?: any, useDefaultIfValuesIsEmpty?: boolean): any {
    const property: DmProperty = protertiesHolder && protertiesHolder.properties ? protertiesHolder.properties.find((property: DmProperty) => property.type === type && property.code === code) || null : null;

    defaultValue = useDefaultIfValuesIsEmpty && !property ? null : defaultValue;

    return property && property.values.length > 0 ? property.values[0] : defaultValue || null;
  }

  public static getPropertyValues(protertiesHolder: any, code: string, type: string): any {
    const property: DmProperty = protertiesHolder && protertiesHolder.properties ? protertiesHolder.properties.find((property: DmProperty) => property.type === type && property.code === code) || null : null;

    return property && property.values.length > 0 ? property.values : [];
  }

  public static goBack(caller: any) {
    const historyIndex: number = caller.$store.getters.historyIndex > 2 ? caller.$store.getters.historyIndex - 2 : 0;
    const historyPage: any = caller.$store.getters.history[historyIndex];
    caller.$store.dispatch("setHistoryIndex", historyIndex);
    caller.$router.push({ name: historyPage.name, params: { ...historyPage.params }, query: historyPage.query });
  }

  public static isExternalLink(url: string) {
    return url.startsWith("http");
  }
}
export default Utils;

import { GetterTree } from "vuex";
import StoreStateInterface from "./store-state-interface";

const storeGetters: GetterTree<StoreStateInterface, any> = {
  brand: (state) => {
    return state.brand;
  },

  signboardContents: (state) => {
    return state.signboardContents;
  },

  magazineContents: (state) => {
    return state.magazineContents;
  },
};
export default storeGetters;

export default class DmProperty {
  private _type: string;
  private _code: string;
  private _label: string;
  private _values: Array<any>;

  constructor(type: string, code: string, label: string, values: Array<any>) {
    this._type = type;
    this._code = code;
    this._label = label;
    this._values = values;
  }
  get type(): string {
    return this._type;
  }

  set type(type: string) {
    this._type = type;
  }

  get code(): string {
    return this._code;
  }

  set code(code: string) {
    this._code = code;
  }

  get label(): string {
    return this._label;
  }

  set label(label: string) {
    this._label = label;
  }

  get values(): Array<any> {
    return this._values;
  }

  set values(values: Array<any>) {
    this._values = values;
  }
}

import DmDayOfWeek from "@/vue/domain/content/dm-days-of-week";
import DmSchedulingStatus from "@/vue/domain/content/dm-scheduling-status";
import DmContentType from "@/vue/domain/content/dm-content-type";
import DmProperty from "@/vue/domain/content/dm-property";

export default class DmContent {
  private _uniqueId: string;
  private _alias: string;
  private _promotion: string;
  private _type: DmContentType;
  private _name: string;
  private _priority: number;
  private _startDate: string;
  private _endDate: string;
  private _startTime: string;
  private _endTime: string;
  private _daysOfWeek: Array<DmDayOfWeek>;
  private _products: Array<string>;
  private _schedulingStatus: DmSchedulingStatus;
  private _properties: Array<DmProperty>;

  constructor(
    uniqueId: string,
    alias: string,
    promotion: string,
    type: DmContentType,
    name: string,
    priority: number,
    startDate: string,
    endDate: string,
    startTime: string,
    endTime: string,
    daysOfWeek: Array<DmDayOfWeek>,
    products: Array<string>,
    schedulingStatus: DmSchedulingStatus,
    properties: Array<DmProperty>
  ) {
    this._uniqueId = uniqueId;
    this._alias = alias;
    this._promotion = promotion;
    this._type = type;
    this._name = name;
    this._priority = priority;
    this._startDate = startDate;
    this._endDate = endDate;
    this._startTime = startTime;
    this._endTime = endTime;
    this._daysOfWeek = daysOfWeek;
    this._products = products;
    this._schedulingStatus = schedulingStatus;
    this._properties = properties;
  }

  get uniqueId(): string {
    return this._uniqueId;
  }

  set uniqueId(uniqueId: string) {
    this._uniqueId = uniqueId;
  }

  get alias(): string {
    return this._alias;
  }

  set alias(alias: string) {
    this._alias = alias;
  }

  get promotion(): string {
    return this._promotion;
  }

  set promotion(promotion: string) {
    this._promotion = promotion;
  }

  get type(): DmContentType {
    return this._type;
  }

  set type(type: DmContentType) {
    this._type = type;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get priority(): number {
    return this._priority;
  }

  set priority(priority: number) {
    this._priority = priority;
  }

  get startDate(): string {
    return this._startDate;
  }

  set startDate(startDate: string) {
    this._startDate = startDate;
  }

  get endDate(): string {
    return this._endDate;
  }

  set endDate(endDate: string) {
    this._endDate = endDate;
  }

  get startTime(): string {
    return this._startTime;
  }

  set startTime(startTime: string) {
    this._startTime = startTime;
  }

  get endTime(): string {
    return this._endTime;
  }

  set endTime(endTime: string) {
    this._endTime = endTime;
  }

  get daysOfWeek(): Array<DmDayOfWeek> {
    return this._daysOfWeek;
  }

  set daysOfWeek(daysOfWeek: Array<DmDayOfWeek>) {
    this._daysOfWeek = daysOfWeek;
  }

  get products(): Array<string> {
    return this._products;
  }

  set products(products: Array<string>) {
    this._products = products;
  }

  get schedulingStatus(): DmSchedulingStatus {
    return this._schedulingStatus;
  }

  set schedulingStatus(schedulingStatus: DmSchedulingStatus) {
    this._schedulingStatus = schedulingStatus;
  }

  get properties(): Array<DmProperty> {
    return this._properties;
  }

  set properties(properties: Array<DmProperty>) {
    this._properties = properties;
  }
}
